import { LoadingView } from "@/views/ErrorView";
import {
    AudiobooksSearch,
    getAudioAvailabilityCondition,
    getInterestedCondition,
    getLanguageCondition,
    getStateCondition,
    getTextCondition,
} from "./components/AudiobookSearch"
import { AudiobookUserInfo, useGetAudiobooksQuery, useGetUsersByIdUserInfoQuery } from "@/api/generatedApi";
import { ErrorComponent } from "@/components/util/ErrorComponent";
import { useSelector } from "react-redux";
import { selectUser } from "@/store/userSlice";
import useTranslation from "@/i18n";
import { AudiobookCard } from "./components/AudiobookCard";
import { selectAudiobookSearch } from "@/store/audiobookSearchSlice";


export default function AudiobookList() {
    const t = useTranslation();

    const searchOptions = useSelector(selectAudiobookSearch)
    const loggedInUser = useSelector(selectUser)!;
    const { data: audiobooks, error: audiobookError } = useGetAudiobooksQuery();
    const { data: audiobookUserInfos, error: infoError } = useGetUsersByIdUserInfoQuery({ id: loggedInUser.id });

    if (audiobookError) {
        return <ErrorComponent error={audiobookError} />
    }
    if (infoError) {
        return <ErrorComponent error={infoError} />
    }

    if (audiobooks === undefined || audiobookUserInfos === undefined) {
        return <LoadingView description={""} />
    }

    const userInfoMap = new Map<string, AudiobookUserInfo>()
    audiobookUserInfos.forEach((userInfo) => userInfoMap.set(userInfo.audiobookId, userInfo))

    const audiobooksToShow = audiobooks.filter((a) =>
        getTextCondition(searchOptions.text, a)
        && getStateCondition(searchOptions.state, a, userInfoMap.get(a.id))
        && getInterestedCondition(searchOptions.interested, a, userInfoMap.get(a.id))
        && getLanguageCondition(searchOptions.language, a)
        && getAudioAvailabilityCondition(searchOptions.audioAvailability, a)
    )

    return (
        <div>
            <div className="flex flex-col justify-center items-center">
                <h1 className="m-3 text-2xl leading-tight">
                    {audiobooksToShow.length} {t("audiobooks")}
                </h1>

                <AudiobooksSearch />

                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
                    {audiobooksToShow.map((audiobook) => {
                        const info = audiobookUserInfos.find(it => it.audiobookId === audiobook.id)
                        return <AudiobookCard key={audiobook.id} audiobook={audiobook} info={info} />
                    })}
                </div>
            </div>
        </div>
    )
}
