import { inputCls } from "@/styles"
import { useEffect, useState } from "react"
import { ALL_LANGUAGES } from "@/i18n"
import { Language, usePostUsersByIdMutation } from "@/api/generatedApi";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setLoggedInUser } from "@/store/userSlice";
import { setErrorNotification, setSuccessNotification } from "@/store/notificationSlice";
import { getErrorMessage } from "@/components/util/ErrorComponent";
import useTranslation from "@/i18n";
import { ButtonComponent } from "@/components/ui/ButtonComponent";
import { InputComponent } from "@/components/ui/InputComponent";
import { Card } from "@/components/Card";

export function ProfileView() {
    const dispatch = useDispatch()
    const t = useTranslation()

    const userInfo = useSelector(selectUser)!!
    const [updateUserInfo, { data: updatedUser, error }] = usePostUsersByIdMutation()

    const [name, setName] = useState<string>(userInfo.name)
    const [newPassword, setNewPassword] = useState<string>("")
    const [confirmPassword, setConfirmPassword] = useState<string>("")
    const [language, setLanguage] = useState<Language>(userInfo.language)


    useEffect(() => {
        if (error) {
            dispatch(setErrorNotification({ text: t(getErrorMessage(error)) }))
            return;
        }
        if (updatedUser === undefined) {
            return;
        }
        setNewPassword("")
        setConfirmPassword("")
        let newUserInfo = {
            "language": language,
            "name": name,
            "isAdmin": userInfo.isAdmin,
            "id": userInfo.id,
            "sessionToken": userInfo.sessionToken,
            "listenedCount": userInfo.listenedCount,
            "ignoredCount": userInfo.ignoredCount,
        }
        dispatch(setLoggedInUser(newUserInfo))
        dispatch(setSuccessNotification({ text: t("updateUserNotification") }));
    }, [updatedUser, error])

    function saveCurrentUser(e: { preventDefault: () => void }) {
        e.preventDefault()
        updateUserInfo({ id: userInfo.id, updateUser: { name, newPassword, language } })
    }

    const hasChanges =
        name !== userInfo.name ||
        newPassword !== "" ||
        confirmPassword !== "" ||
        language !== userInfo.language

    const labelClasses = "block text-gray-700 dark:text-gray-200 text-sm font-bold mb-2"
    const inputClasses = inputCls + " "
    return (
        <div>
            <div className="flex justify-center">
                <h1 className="text-2xl leading-tight m-3">{t("profile")}</h1>
            </div>
            <Card className={`container m-auto my-5 p-5`}>

                <form onSubmit={(e) => saveCurrentUser(e)}>
                    <div className="mt-3">
                        <InputComponent value={name} id="username-input"
                            onChange={(value) => setName(value)}
                            label={t("profileUsername")} />
                    </div>
                    <div className="mt-3">
                        <InputComponent value={newPassword} id="password-input"
                            onChange={(value) => setNewPassword(value)}
                            label={t("profilePassword")} type="password" />
                    </div>
                    <div className="mt-3">
                        <InputComponent value={confirmPassword} id="confirm-password-input"
                            onChange={(value) => setConfirmPassword(value)}
                            label={t("profileConfirmPassword")} type="password" />
                    </div>
                    <div className="my-3">
                        <label htmlFor="language-input" className={labelClasses}>
                            {t("profileLanguage")}
                        </label>
                        <select
                            className={inputClasses}
                            id="language-input"
                            value={language}
                            onChange={(e) => setLanguage(e.target.value as Language)}
                        >
                            {ALL_LANGUAGES.map((l) => (
                                <option key={l} value={l}>
                                    {l}
                                </option>
                            ))}
                        </select>
                    </div>
                    <ButtonComponent text={t("profileSaveChanges")}
                        disabled={!hasChanges || newPassword !== confirmPassword}
                        onClick={() => {
                        }}
                        className="ml-0"
                    />

                </form>
            </Card>
        </div>

    )
}
