import { BarElement, CategoryScale, Chart as ChartJS, LinearScale, Title, Tooltip } from "chart.js";
import { SeriesStatisticElement } from "@/api/generatedApi";
import { Bar } from "react-chartjs-2";
import useTranslation from "@/i18n";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
);

// don't show legend and set scales
export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
};


export function SeriesStatistics(props: { seriesData: { [x: string]: SeriesStatisticElement[]; }; }) {
    const { seriesData } = props;
    const t = useTranslation();

    const maxElementsPerSeries = Object.values(seriesData)
        .map((ele) => ele.length)
        .reduce((a, b) => Math.max(a, b));

    const colors = ['rgba(53,235,93,0.5)', 'rgba(235,53,53,0.5)', 'rgba(53, 162, 235, 0.5)',
        'rgba(235,235,53,0.5)', 'rgba(53,235,235,0.5)', 'rgba(144,53,235,0.5)',
        'rgba(235,53,235,0.5)'];
    let datasets = [];
    for (let i = 0; i < maxElementsPerSeries; i++) {
        datasets.push({
            label: i.toString(), //Object.keys(seriesData).map((ele) => {
            //return seriesData[ele].length > i ? `${seriesData[ele][i].position}: ${seriesData[ele][i].audiobookName}` : ""
            //}),
            data: Object.keys(seriesData).map((ele) => {
                return seriesData[ele].length > i ? seriesData[ele][i].duration / 3600000 : 0
            }),
            backgroundColor: colors[i % colors.length],
        })
    }

    const seriesStatisticsData = {
        labels: Object.keys(seriesData),
        datasets: datasets,
    };

    // @ts-ignore
    return (
        <>
            <h2 className="text-xl">{t("statisticsTotalSeriesLength")}</h2>
            <Bar options={options} data={seriesStatisticsData} />
        </>
    )
}
