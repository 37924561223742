import React, { useEffect, useState } from "react";
import { usePostUsersMutation } from "@/api/generatedApi";
import { inputCls } from "@/styles";
import { ButtonComponent } from "@/components/ui/ButtonComponent";

type Message = {
    text: string;
    isError: boolean;
};


export const CreateUserForm = (props: { successCallback: () => void }) => {
    const [username, setUsername] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);
    const [message, setMessage] = useState<Message | null>(null);
    const showMessage = (m: Message | null) => {
        if (m === null) {
            return null;
        }
        return <div className="col-span-2">{m.text}</div>;
    };
    const [createUser, { data: newUser, error }] = usePostUsersMutation()

    useEffect(() => {
        if (error) {
            return
        }
        if (newUser === undefined) {
            return
        }

        setUsername("")
        setIsAdmin(false)
        setMessage({
            text: "Created user with the following password: " + newUser.password,
            isError: false,
        })
        props.successCallback()
    }, [newUser, error])

    const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        createUser({ createUser: { name: username, isAdmin: isAdmin, language: "english" } })
    };

    return (
        <div className="">
            <form
                onSubmit={submitForm}
                className="flex"
            >
                <div className="mb-4">
                    <label
                        className="block text-sm font-bold mb-2"
                        htmlFor="username">
                        Username
                    </label>
                    <input
                        className={inputCls}
                        type="text"
                        placeholder={"Username"}
                        value={username}
                        onChange={(event) => setUsername(event.target.value)}
                    />
                </div>
                <div className="flex flex-col pl-10">
                    <label
                        className="block text-sm font-bold mb-2 align-center"
                        htmlFor="is-admin">
                        Is Admin
                    </label>
                    <input
                        className="place-center"
                        checked={isAdmin}
                        type="checkbox"
                        onChange={(event) => setIsAdmin(event.target.checked)}
                    />
                </div>
                <div className="pl-10 flex flex-wrap content-center">
                    <ButtonComponent text="Create User" onClick={() => { }} />

                </div>
                <div className="pl-5 flex flex-wrap content-center">
                    {showMessage(message)}
                </div>
            </form>
        </div>
    );
};
