import { btnPrimCls } from "@/styles"
import { Link } from "react-router-dom"
import { LoadingView } from "../ErrorView"
import { ErrorComponent } from "@/components/util/ErrorComponent";
import { Audiobook, AudiobookUserInfo, Series, useGetAudiobooksQuery, useGetSeriesQuery, useGetUsersByIdUserInfoQuery, UserWithToken } from "@/api/generatedApi";
import { useSelector } from "react-redux";
import { selectUser } from "@/store/userSlice";
import useTranslation from "@/i18n";
import { SeriesSearch, getTextCondition } from "@/views/series/components/SeriesSearch";
import { selectSeriesSearch } from "@/store/seriesSearchSlice";
import { Card } from "@/components/Card";
import { Progressbar } from "@/components/Progressbar";
import { sum } from "@/utils/util";

export function SeriesCard(
    { series, associatedBooks, bookInfos }: {
        series: Series,
        associatedBooks: Audiobook[]
        bookInfos: AudiobookUserInfo[]
    }) {
    const t = useTranslation()

    const loggedInUser: UserWithToken | null = useSelector(selectUser)

    const totalBookTime = sum(...associatedBooks.map(book => book.lengthMsec))
    const totalListenedTime = sum(...bookInfos.map(book => book.progressMSec))
    const percentage = Math.min(Math.max((totalListenedTime / totalBookTime) * 100, 0), 100);

    return (
        <Card className={`mt-3 mb-3 p-3`}>
            <div className="flex justify-between">
                <div className="w-1/2 xl:w-2/3">
                    <Link to={`/series/${series.id}`} key={series.id}>
                        {series.name} ({series.elements.length} {t("seriesTitles")})
                    </Link>
                </div>
                <div className="flex items-center gap-2 lg:gap-3 w-1/3">
                    <Progressbar percentage={percentage} />
                    {loggedInUser && loggedInUser.isAdmin && <Link to={`/series/${series.id}/edit`} className={`${btnPrimCls} py-2 hidden xl:block`}>
                        Edit
                    </Link>}
                    <Link to={`/series/${series.id}`} key={series.id} className={btnPrimCls}>
                        +
                    </Link>
                </div>

            </div>
        </Card>
    )
}

export default function SeriesList() {
    const t = useTranslation()
    const searchOptions = useSelector(selectSeriesSearch)
    const loggedInUser: UserWithToken = useSelector(selectUser)!

    const { data: seriesList, error: seriesError } = useGetSeriesQuery()
    const { data: books, error: bookErr } = useGetAudiobooksQuery()
    const { data: infos, error: infoErr } = useGetUsersByIdUserInfoQuery({ id: loggedInUser.id })

    const err = seriesError || bookErr || infoErr
    if (err) {
        return <ErrorComponent error={err} />
    }

    if (!seriesList || !books || !infos) return <LoadingView description={"loading"} />

    const seriesFiltered = seriesList.filter((a) =>
        getTextCondition(searchOptions.text, a)
    )

    const seriesToShow = [...seriesFiltered].sort((b, a) => a.name > b.name ? -1 : 1)
    const bookMap = new Map<string, { associatedBooks: Audiobook[], bookInfos: AudiobookUserInfo[] }>()
    seriesToShow.forEach((series) => {
        const seriesBookIds = series.elements.map(ele => ele.audiobookId)
        const seriesBooks = books.filter(book => seriesBookIds.includes(book.id))
        const seriesBookInfos = infos.filter(info => seriesBookIds.includes(info.audiobookId))
        bookMap.set(series.id, { associatedBooks: seriesBooks, bookInfos: seriesBookInfos })
    })

    return (<div>
        <div className="flex justify-center">
            <h1 className="m-3 text-2xl leading-tight">
                {t("series")}
            </h1>
        </div>
        <div className="max-w-5xl mx-auto m-3">
            <SeriesSearch />
            {seriesToShow.map((ele, index) =>
                <SeriesCard key={index} series={ele} {...bookMap.get(ele.id)!} />
            )}
        </div>
    </div>)
}
