export function Progressbar({ percentage, color }: { percentage: number, color?: string }) {
    return (
        <div className="relative w-full bg-gray-200 rounded-full h-6">
            {/* Progress fill */}
            <div
                className={`absolute top-0 left-0 h-full rounded-full ${color || "bg-green-400/90"}`}
                style={{ width: `${percentage}%` }}
            ></div>
            {/* Percentage display */}
            <div className="absolute inset-0 flex items-center justify-end px-2 text-sm font-semibold text-gray-800">
                {percentage.toFixed(1)}%
            </div>
        </div>
    );
};
