import useTranslation from "@/i18n";
import { useDispatch, useSelector } from "react-redux";
import { inputCls } from "@/styles";
import { Series } from "@/api/generatedApi";
import { selectSeriesSearch, setSeriesSearch } from "@/store/seriesSearchSlice";


export function getTextCondition(text: string, s: Series) {
    text = text.toLowerCase();
    return s.name.toLowerCase().includes(text);
}

export function SeriesSearch() {
    const t = useTranslation();
    const dispatch = useDispatch();
    const searchOpt = useSelector(selectSeriesSearch);

    return (
        <div className="flex">
            <div className="block relative">
                <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                    <svg viewBox="0 0 24 24" className="h-4 w-4 fill-current text-gray-500">
                        <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1
                                 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"/>
                    </svg>
                </span>
                <input
                    value={searchOpt.text}
                    placeholder={t("search")}
                    className={`${inputCls} pl-8`}
                    onChange={(e) => {
                        e.preventDefault();
                        const cpSearchOpt = Object.assign({}, searchOpt);
                        cpSearchOpt.text = e.target.value;
                        dispatch(setSeriesSearch(cpSearchOpt));
                    }}
                />
            </div>
        </div>
    )
}
