import { useGetUsersQuery } from "@/api/generatedApi";
import { ErrorComponent } from "@/components/util/ErrorComponent";
import { LoadingView } from "@/views/ErrorView";
import useTranslation from "@/i18n";
import { UserList } from "@/views/admin/Users";
import { CreateUserForm } from "@/views/admin/CreateUser";
import { Audible } from "@/views/admin/Audible";
import { Link } from "react-router-dom";
import { ButtonComponent } from "@/components/ui/ButtonComponent";
import { AdminStatistics } from "./AdminStatistics";
import { Card } from "@/components/Card";

export function AdminView() {
    const t = useTranslation()

    const { data: users, error, refetch } = useGetUsersQuery()
    if (error) {
        return <ErrorComponent error={error} />
    }
    if (users === undefined) {
        return <LoadingView description={""} />
    }

    return (
        <>
            <h1 className="flex justify-center m-3 text-2xl">
                {t("navBarAdmin")}
            </h1>

            <div id="adminActionBar" className="container m-auto" >
                <ButtonComponent text={<Link to="/audiobooks/add">{t("audiobookAdd")}</Link>}
                    onClick={() => { }} />
                <ButtonComponent text={<Link to="/series/add">{t("seriesAdd")}</Link>}
                    onClick={() => { }} />
            </div>

            <h2 className="flex justify-center p-3 text-xl">
                Users
            </h2>

            <Card className={`container m-auto`}>
                <UserList users={users} refreshUsers={refetch} />
            </Card>

            <h2 className="flex justify-center p-3 text-xl">
                Create User
            </h2>

            <Card className={`container m-auto p-5`}>
                <CreateUserForm successCallback={refetch} />
            </Card>

            <Audible />
            <AdminStatistics />
        </>
    )
}
