import { LabelComponent } from "@/components/ui/LabelComponent";
import { FileUploader } from "@/components/ui/FileUploader";
import React, { useEffect, useState } from "react";
import { setErrorNotification, setSuccessNotification } from "@/store/notificationSlice";
import { useDispatch } from "react-redux";
import { usePostAudiobooksByIdAudiofileMutation } from "@/api/generatedApi";
import { ButtonComponent } from "@/components/ui/ButtonComponent";
import { getErrorMessage } from "@/components/util/ErrorComponent";
import { Card } from "@/components/Card";

export function AudiofileUploadComponent(props: { audiobookId: string }) {
    const dispatch = useDispatch();

    const [updateAudiofile, { data: updateAudiofileData, error: updateAudiofileError }] = usePostAudiobooksByIdAudiofileMutation();

    const [audiodataBlob, setAudiofileBlob] = useState<Blob>();
    const [uploadingAudioFile, setUploadingAudioFile] = useState(false);

    function saveAudiofile() {
        if (audiodataBlob === undefined) {
            dispatch(setErrorNotification({ text: "No audiofile selected" }));
            return;
        }
        updateAudiofile({ id: props.audiobookId, body: audiodataBlob });
    }

    useEffect(() => {
        if (updateAudiofileError !== undefined) {
            dispatch(setErrorNotification({ title: "updating failed", text: getErrorMessage(updateAudiofileError) }));
            setUploadingAudioFile(false);
        }
        if (updateAudiofileData !== undefined) {
            dispatch(setSuccessNotification({ text: "Audiofile update started, this may take a while if converting is required." }));
            setUploadingAudioFile(false);
        }
    }, [updateAudiofileData, updateAudiofileError]);

    return (
        <Card className={`p-5`}>
            <LabelComponent text="Audiofile" />
            <p>Supported formats are:</p>
            <ul className="ml-6 mb-3 list-disc">
                <li>AAX audiofile</li>
                <li>AAC/M4A audiofile</li>
                <li>MP3 audiofile</li>
                <li>List of zipped MP3 audiofiles</li>
                <li>List of zipped AAX audiofiles</li>
            </ul>
            <FileUploader className="mb-2" onFileSelect={(file) => setAudiofileBlob(file)} />
            <ButtonComponent className="ml-0"
                text="Save Audiofile"
                onClick={(e) => {
                    e.preventDefault();
                    saveAudiofile();
                    setUploadingAudioFile(true);
                }}
                loading={uploadingAudioFile}
            />
        </Card>
    )
}
